import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Image } from '@uz/unitz-components-web/Image';
import { Row, Col, Button } from 'antd';
import { Link } from '@uz/unitz-components-web/Link';
import _ from 'lodash';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const CustomText = styled.div`
  div {
    p {
      margin-bottom: 0;
      color: ${gstyles.colors.sub};
    }
  }
`;

const View8 = () => {
  return (
    <DIV className="pageContainer">
      <div className="content flex flex-col jusitfy-center items-center">
        <div className="mb-6">
          <ResponsiveProps
            md={{ ratio: 0.5, width: 768, height: 768 * 0.5 }}
            xs={{ ratio: 0.6, width: 400, height: 420 * 0.6 }}
            lg={() => ({ ratio: 0.4, width: 1200, height: 800 * 0.4 })}
          >
            {(resProps) => (
              <Image
                size="cover"
                src={ctx.apply('ctf.findImage', ctx.get('sectionData.section'), 'image', '')}
                className="hero-image"
                alt="banner"
                {...resProps}
              />
            )}
          </ResponsiveProps>
        </div>

        <div className="text-center">
          <h1 className="text-2xl mb-4 lg:text-5xl font-semibold text-brandb-darker">
            {ctx.get('sectionData.section.shortText')}
          </h1>
          <div className="mb-8">
            <CustomText>
              <div className="text-sm lg:text-xl mb-0">
                {ctx.apply(
                  'ctf.renderHTMLText',
                  { name: ctx.get('sectionData.section.name') },
                  'longText.longText',
                  null
                )}
              </div>
              <div className="text-sm lg:text-xl mb-0">
                {ctx.apply(
                  'ctf.renderHTMLText',
                  { name: ctx.get('sectionData.section.name') },
                  'detailText.detailText',
                  null
                )}
              </div>
            </CustomText>
          </div>
          <Row className="justify-center">
            {ctx.get('sectionData.children', []).map((item, index) => {
              return (
                <Col key={`item_${index}`} xs={12} md={16} lg={16} xl={16}>
                  <Link to={ctx.apply('routeStore.toLocale', _.get(item, 'linkHref'))}>
                    <ResponsiveProps xs={{ size: 'medium' }} md={{ size: 'medium' }} lg={{ size: 'large' }}>
                      {(resProps) => (
                        <Button className="w-full" type="primary" {...resProps}>
                          {_.get(item, 'shortText', '')}
                        </Button>
                      )}
                    </ResponsiveProps>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </DIV>
  );
};

export default displayName(View8);
