import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import _ from 'lodash';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadings: hook(() => React.useMemo(() => ({}))),
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: true }))),
            sectionData: hook((ctx) => {
              const section = ctx.apply('ctf.findSection', { name: 'Content404' });
              const children = _.get(section, 'sections', []).map((section) => {
                return ctx.apply('ctf.findEntry', section);
              });
              return {
                section,
                children,
              };
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
        },
      ],
    ],
  },
});

export default bindData;
